// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //base_url: "https://dev.api.sinapsis.holadoc.tk/v1",
  //socket_url: "https://dev.api.sinapsis.holadoc.tk",
  //base_url: "https://qa.api.sinapsis.holadoc.tk/v1",
  /*   base_url: "https://qa2.api.sinapsis.holadoc.tk/v1",
  socket_url: "https://qa2.api.sinapsis.holadoc.tk",  */
  // base_url: "https://qa.api.sinapsis.holadoc.tk/v1",
  // socket_url: "https://qa.api.sinapsis.holadoc.tk",
/*   
  base_url: "https://api.sinapsishealth.com/v1",
  socket_url: "https://api.sinapsishealth.com", */
  // base_url: "https://demo.api.sinapsis.holadoc.tk/v1",
  //  socket_url: "https://demo.api.sinapsis.holadoc.tk",
  // base_url: "https://qa.api.poweredby.holadoc.tk/v1",
  // socket_url: "https://qa.api.poweredby.holadoc.tk",
  base_url: 'https://dev.api.poweredby.holadoc.tk/v1',
  organizationId: "614aa191c9441820975681f4",
  socket_url: 'https://dev.api.poweredby.holadoc.tk',

  defaultLanguage: "es-ES",
  supportedLanguages: ["en-US", "es-ES"],
  // userIdleTime: 600,
  userIdleTime: 1800,
  logOutDuration: 1800,
  qbEnvirnoment: "production",
  //organizationId: "63442b879172715afece9e04", //PROD ID
  //organizationId: "6151c649010f726c775c506e", //QA ID
/*   base_url: "https://dev.api.framework.holadoc.tk",
  socket_url: "https://dev.api.framework.holadoc.tk", */
  //organizationId: "614aa191c9441820975681f4",
  //organizationId: '609aa12c5626a05ec6801dbb', //DEMO ID
  clevertapActive: false,
  clevertapId: "WW6-75W-6R7Z",
  //clevertapId: '4WK-66R-5Z7Z',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
